<template>
	<div class="main-contents hr agent">
		<div class="tit">하이프로 관리자 관리</div>
		<div class="search-box">
			<SelectComp cdId="PRO101" filter="0" v-model="filter.empDivCd" title="담당분야" :isAll="true"/>
			<nbsp/>
			<InputComp v-model="filter.inputTxt" placeholder="관리자명" @keyup.enter="getAdmMberList('1');" />
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getAdmMberList('1');"/>

			<div class="sub_btn" @click="$router.push('MEM970M02')">+ 관리자 등록</div>
			<div class="sub_btn delete" @click="admDelte()">삭제</div>
		</div>
		<!-- 관리자 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="4%">
					<col width="4%">
					<col width="10%">
					<col width="10%">
					<col width="21%">
					<col width="5%">
					<col width="4%">
					<col width="21%">
					<col width="13%">
					<col width="8%">
				</colgroup>
				<thead>
					<tr>
						<th>선택</th>
						<th>NO</th>
						<th>관리자명</th>
						<th>관리자 번호</th>
						<th>아이디</th>
						<th>직급</th>
						<th>성별</th>
						<th>이메일</th>
						<th>핸드폰번호</th>
						<th>담당분야</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="admMberList.length > 0">
						<tr v-for="(row, rowIdx) in admMberList" :key="rowIdx">
							<td class="score">
								<SelectComp type="checkbox" v-model="chkRow" :list="row.mberSeq+ ':'" />
							</td>
							<td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rowIdx + 1)}}</td>
							<td class="score name" @click="openPopup(row.mberSeq)">{{row.mberNm}} &#9654;</td>
							<td class="score">{{row.mberSeq}}</td>
							<td class="score">{{row.loginId}}</td>
							<td class="score">{{row.positionNm}}</td>
							<td class="score">{{row.gender | gender}}</td>
							<td class="score">{{row.email}}</td>
							<td class="score">{{row.hpNo | cell}}</td>
							<td class="score"><SelectComp type="text" cdId="PRO101" v-model="row.mberDivCd" /></td>
						</tr>
					</template>

					<template v-else>
						<!-- 관련내용 없을 경우 -->
						<tr>
							<td colspan="10" class="none">관리자가 없습니다!</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		
		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			filter : {
				pageIndex : 1
			},

			admMberList : [],
			pageInfo : {},
			chkRow : [],
		}
	},

	components : {
		pagingComp
	},

	mounted() {
		this.getAdmMberList();
	},

	methods : {
		// 관리자 목록 조회
		getAdmMberList(div) {
			var param = this.filter;
			param.pageUnit = '25';
			param.pageSize = '10';
			if(div) param.pageIndex = '1';

			this.$.httpPost('/api/mem/adm/pro/getAdmMberList', param)
				.then(res => {
					//console.log('getAdmMberList RESULT', res);

					this.admMberList = res.data.admMberList;
					this.pageInfo = res.data.pageInfo;

					if(this.chkRow.length > 0) this.chkRow = [];

				}).catch(this.$.httpErrorCommon);
		},

		// 상세팝업
		openPopup(seq) {
			var param = {};
			param.reqMberSeq = seq;
			param.div = 'adm';

			this.$.popup('/adm/mem/MEM923P02', param);
		},

		goPage(page) {
			this.srcFilter.pageIndex = page;
			this.getAdmMberList();
		},

		// 삭제
		admDelte() {
			if(this.chkRow.length == 0) {
				alert('선택된 관리자가 없습니다.');
				return false;
			}

			var param = {};
			param.admMberList = this.chkRow;

			//console.log('ADMIN DELETE :', param);

			this.$.httpPost('/api/mem/adm/pro/deleteAdmMber', param)
				.then(() => {
					alert('삭제 완료되었습니다.');
					this.getAdmMberList('1');

				}).catch(this.$.httpErrorCommon);
		}
	}
}
</script>